<template>
  <div class="container requirement">
    <div class="search">
      <el-input
        v-model.trim="form.queryStr"
        placeholder="请输入用户昵称或需求描述关键字"
        clearable
        @keyup.enter.native="query"
      >
        <el-button @click="query" slot="append">搜索</el-button>
      </el-input>
    </div>

    <div class="list" v-loading="loading">
      <div
        :class="[
          'list_item',
          'pd20',
          'cursorP',
          (index + 1) % 3 == 0 ? 'mr0' : '',
        ]"
        v-for="(item, index) in list"
        @click="toDetails(item)"
      >
        <div class="flex alc mb10 pb10 bbd1">
          <el-avatar :size="38" :src="item.wxUser.headimgurl" class="mr10">
            <img
              src="https://www.zizhihelp.com/upload/static/web/mine_touxiang.png"
            />
          </el-avatar>

          <div>
            <div class="c333 flex alc mb5">
              <div class="mr5">{{ item.wxUser.nickname }}</div>

              <img
                v-if="item.wxUser.status == '已开通'"
                class="user_type"
                src="https://www.zizhihelp.com/upload/static/vip.png"
              />

              <img
                v-else
                class="user_type"
                src="https://www.zizhihelp.com/upload/static/yonghu.png"
              />
            </div>

            <div class="c999 fz14">
              {{ item.createTime }}
            </div>
          </div>
        </div>

        <div
          class="c666 fz14 desc"
          v-html="$options.filters.formatTitle(item.requirement, form.queryStr)"
        ></div>
      </div>
    </div>

    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
      :current-page="form.pageNum"
      :page-size="form.pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 需求广场 */
import { getRequirementPage } from "@/api/requirement";

export default {
  data() {
    return {
      list: [],
      form: {
        pageNum: 1,
        pageSize: 9,
        queryStr: "", // 搜索关键字
      },
      total: 0,
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getListData() {
      this.loading = true;
      const { data } = await getRequirementPage(this.form);
      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },

    /* 操作 */
    // 查询操作
    query() {
      this.form.pageNum = 1;
      this.getListData();
    },
    // 分页
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getListData();
    },
    // 跳转到详情页
    toDetails(item) {
      this.$openNewWindow({
        name: "requirementDetails",
        params: { id: item.id },
      });
    },

    /* 初始化 */
    __init__() {
      this.getListData();
    },
  },

  mounted() {
    this.getListData();
  },
};
</script>

<style lang="less" scoped>
.requirement {
  background-color: #fff;
  min-height: calc(100vh - 344px);
  padding-bottom: 20px;

  .search {
    padding: 20px;

    /deep/.el-input__inner {
      line-height: 40px !important;
      height: 40px !important;
    }

    /deep/.el-input-group__append {
      background-color: #fff;
      cursor: pointer;
      background-color: #4f7bff;
      color: #fff;
      border-color: #4f7bff;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    min-height: 300px;

    .list_item {
      width: calc((100% - 40px) / 3);
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 4px 0px rgba(157, 195, 221, 0.5);
      margin-right: 20px;
      margin-bottom: 20px;

      &:hover {
        background: #ddecff;
      }
    }

    .desc {
      overflow: hidden; // 溢出隐藏
      text-overflow: ellipsis; // 溢出用省略号显示
      display: -webkit-box; // 作为弹性伸缩盒子模型显示。
      -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
      -webkit-line-clamp: 4; // 显示的行数
      line-height: 1.5;
    }

    .user_type {
      height: 15px;
    }
  }
}
</style>
